import "../styles/HomeIntro.css";

export default function HomeIntro() {
  return (
    <div className="home-intro">
      <div className="text-container">
        <p className="paragraph">Welcome to my digital space!</p>
        <p className="paragraph">
          I'm a skilled<span className="keyword"> software developer</span> with
          expertise in <span className="keyword">machine learning</span>.
          Proficient in <span className="keyword">Python</span>,{" "}
          <span className="keyword">JavaScript</span>, and technologies like{" "}
          <span className="keyword">AWS</span> and{" "}
          <span className="keyword">Docker</span>, I focus on building
          efficient, <span className="keyword">user-centric</span> applications
          that balance functionality and performance.
        </p>
        <p className="paragraph">
          Leveraging frameworks such as <span className="keyword">React</span>{" "}
          and <span className="keyword">Flask</span>, I create seamless
          interfaces while integrating robust{" "}
          <span className="keyword">backend systems</span> using{" "}
          <span className="keyword">cloud services</span> and{" "}
          <span className="keyword">DevOps</span> practices. My experience
          ranges from developing{" "}
          <span className="keyword">machine learning models</span> to deploying{" "}
          <span className="keyword">full-stack solutions</span>, delivering
          innovative, impactful applications.
        </p>
      </div>
      <img
        className="image"
        src={"images/home_intro.png"}
        alt="HomeIntro"
      ></img>
    </div>
  );
}
